
import AGI from '../pages/AGI';
import Aldi from '../pages/Aldi';
import Baker from '../pages/Baker';
import Belk from '../pages/Belk';
import BestBuy from '../pages/BestBuy';
import CircleK from '../pages/CircleK';
import Duke from '../pages/Duke';
import DukeMountain from '../pages/DukeMountain';
import DukeNorthCoast from '../pages/DukeNorthCoast';
import Dunkin from '../pages/Dunkin';
import Emcor from '../pages/Emcor';
import Everbank from '../pages/Everbank';
import FSG from '../pages/FSG';
import HCA from '../pages/HCA';
import { HoneyBakedHamPage as HoneyBakedHam } from '../pages/HoneyBakedHam';
import Michaels from '../pages/Michaels';
import SalesHive from '../pages/SalesHive';
import Simon from '../pages/Simon';
import Sites from '../pages/Sites';
import TA from '../pages/TA';
import Thales from '../pages/Thales';
import USPS from '../pages/USPS';
import WeWork from '../pages/WeWork';
import CPK from '../pages/CPK';
import BassPro from '../pages/BassPro';
import Starbucks from '../pages/Starbucks';
import FlyingJ from '../pages/FlyingJ';
import CampingWorld from '../pages/CampingWorld';
import Northrop from '../pages/Northrop';
import _711 from '../pages/711';
import Refresco from '../pages/Refresco';
import DollarGeneral from '../pages/DollarGeneral';

const customerInfo : { [key: string]: any} = {
    "711": {
      "guid": "54dee359-58e0-4bc8-bfb7-3798b56f37cc",
      "name": "7-11",
      "page": _711
    },
    "agi": {
      "guid" : "189a9d10-53bb-41dd-adb9-8c5b0d00afa9",
      "name" : "AGI",
      "page" : AGI
    },
    "aldi": {
      "guid" : "6a1aa98d-2594-4486-8578-7a8439884546",
      "name" : "Aldi",
      "page" : Aldi
    },
    "baker": {
      "guid": "ffa76347-4b57-459a-bbdf-8c4f0fe0567e",
      "name": "Baker Distributing",
      "page" : Baker
    },
    "basspro": {
      "guid": "52dcd444-f36a-4441-a025-865941753ee4",
      "name": "Bass Pro Shops",
      "page" : BassPro
    },
    "belk": {
      "guid": "a1565e56-3096-4b3b-a68a-12fc2c3b61b2",
      "name": "Belk",
      "page" : Belk
    },
    "bestbuy": {
      "guid": "7502864b-4a97-42b9-a1c1-002933d03d5c",
      "name": "Best Buy",
      "page" : BestBuy
    },
    "cpk": {
      "guid" : "6588d45f-b10f-4e8c-8ff7-302703fd223c",
      "name" : "California Pizza Kitchen",
      "page" : CPK
    },
    "campingworld": {
      "guid" : "b803fe07-e9e8-48d5-b64a-89a688d2ff38",
      "name" : "Camping World",
      "page" : CampingWorld
    },
    "circlek": {
      "guid": "30ad1ee7-0d09-46df-a547-dc7f4c2edd65",
      "name": "Circle K",
      "page" : CircleK
    },
    "dollar_general" : {
      "guid": "d4aaeb7f-3573-43e4-9358-d8a7fc004457",
      "name": "Dollar General",
      "page" : DollarGeneral
    },
    "duke" : {
      "guid": "14600dc8-5338-4ea0-9939-eee5d940f125",
      "name": "Duke JLL",
      "page" : Duke
    },
    "duke-mountain" : {
      "guid": "dec486e2-cb63-42ee-8475-4c83a6fc5618",
      "name": "Duke JLL - Mountain/Palmetto",
      "page" : DukeMountain
    },
    "duke-north-coast" : {
      "guid": "37b3098d-5a70-4a8c-a2eb-94507432e2f0",
      "name": "Duke JLL - North Coastal",
      "page" : DukeNorthCoast
    },
    "dunkin" : {
      "guid": "a0df6cc0-13a2-4baf-98e6-fb94a2db9b25",
      "name": "Dunkin Donuts",
      "page" : Dunkin
    },
    "emcor": {
      "guid": "4002b7dd-ce29-4441-8e77-74088f1bd24c",
      "name": "EMCOR",
      "page" : Emcor
    },
    "everbank": {
      "guid": "6588d45f-b10f-4e8c-8ff7-302703fd223c",
      "name": "Everbank/JLL",
      "page" : Everbank
    },
    "flyingj": {
      "guid": "4289f6a1-4084-4be6-8654-b23d6cc5b92e",
      "name": "Flying J",
      "page" : FlyingJ
    },
    "fsg": {
      "guid": "d816f95f-06d7-4535-96e3-5fa2ebf7e675",
      "name": "FSG",
      "page" : FSG
    },
    "hca": {
      "guid": "5e2a9ec8-a3bd-443b-aa76-48880bdf4bd5",
      "name": "HCA Healthcare",
      "page" : HCA
    },
    "honeybaked": {
      "guid": "48d1c977-37b5-4a82-b7f7-2c7ac9dae874",
      "name": "Honey Baked Ham",
      "page" : HoneyBakedHam
    },
    "michaels": {
      "guid": "d11a2d44-9839-409d-ba8f-f6f9ca071bee",
      "name" : "Michael's",
      "page" : Michaels
    },
    "northrop": {
      "guid": "319f2583-3bb6-46ff-9303-be4a09e7f89b",
      "name" : "Northrop Grumman",
      "page" : Northrop
    },
    "saleshive": {
      "guid": "fc0d5acb-77e1-479d-9fac-d8af91fc8436",
      "name" : "Sales Hive",
      "page" : SalesHive
    },
    "refresco": {
      "guid": "51736e47-7f00-43d7-9ba6-37632d68906b",
      "name" : "Refresco",
      "page" : Refresco
    },
    "simon": {
      "guid": "247b4592-d7f0-4a3a-bd84-189d69819e4a",
      "name": "Simon Malls",
      "page" : Simon
    },
    "sites": {
      "guid": "99b85bcb-252d-4fdd-bcef-8f8d3ae2ace9",
      "name": "National",
      "page" : Sites
    },
    "starbucks": {
      "guid": "905ade6a-1d75-4567-93d5-f35d9f42d033",
      "name": "Starbucks",
      "page" : Starbucks
    },
    "ta": {
      "guid": "9ae92018-ad2b-4c83-8c8f-06bdc646c550",
      "name": "Travel Centers of America",
      "page" : TA
    },
    "thales": {
      "guid": "8cc9a6e1-4804-432c-a423-5782c800ba25",
      "name": "Thales",
      "page" : Thales
    },
    "usps": {
      "guid": "58c6908c-4578-4535-b57a-b6bae86257ea",
      "name": "USPS",
      "page" : USPS
    },
    "wework" : {
      "guid": "836bc55d-2ce3-4645-aa20-73d2ab169bbb",
      "name": "WeWork",
      "page" : WeWork
    }
}



// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const extraGuids =  [
  
  "ab7c5aa8-8fb5-43e2-b145-cc8a0ef456eb",
  "aca1728f-4774-4390-ba8a-6a5ecbb7c840",
  "1836cc06-4171-4a5b-9b3c-fe1b36ce4cf8",
  "95aa1b82-673c-4816-8316-c6be2bb98a79",
  "e17ff0bd-a71a-4471-a7d1-c3abec0cecb0",
  "5d5f5197-19ec-43ae-b3b0-ab931e920507",
  "fcc42d7f-72d2-479b-aed1-602ade20cdac",
  "a247b778-95c5-4f1f-9f67-164f4abf49f2",
  "169c9cf3-e5d4-4182-b6a2-66e310672d3e"
]

export default customerInfo;